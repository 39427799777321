<!-- eslint-disable max-len, vue/max-len -->
<template>
  <section
    id="tarieven"
    class="mt-100 2xl:mt-150 scroll-mt-150"
  >
    <div class="container">
      <div class="rounded-3xl bg-gradient-to-br from-primary-400 to-primary-500 p-25 md:p-50 xl:p-75">
        <div class="flex flex-col items-center text-center gap-y-10 mb-30">
          <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold">
            Onze <span class="marker marker-white">tarieven</span>
          </h2>
          <h3 class="text-xl md:text-2xl xl:text-3xl font-bold">
            Stel jouw eigen Clubee pakket samen.
          </h3>
        </div>

        <div class="rounded-2xl bg-white shadow-xl">
          <form
            class="flex flex-wrap"
            @change.prevent="calculatePrice"
            @submit.prevent
          >
            <div class="w-full lg:w-2/3 p-25 xl:p-50 border-b lg:border-b-0 lg:border-r border-gray-200">
              <h3 class="text-3xl font-bold">
                <span class="marker marker-md">Maandelijkse</span>
                kosten
              </h3>
              <div class="flex flex-col md:flex-row md:items-center mt-30">
                <span class="md:w-1/3 font-medium mb-10 md:mb-0">
                  Soort club
                </span>
                <div class="bg-gray-100 w-fit rounded-full p-3">
                  <button
                    type="button"
                    :class="['text-base px-20 font-medium text-gray-500 transition-all py-8 md:py-10', { 'rounded-full !text-black bg-white shadow-md': packageType === 0 }]"
                    @click="packageType = 0; $gtm.trackEvent({ category: 'Tarieven Toggle', value: 'Businessclub' }); calculatePrice();"
                  >
                    Businessclub
                  </button>
                  <button
                    type="button"
                    :class="['text-base px-20 font-medium text-gray-500 transition-all relative py-8 md:py-10', { 'rounded-full !text-black bg-white shadow-md': packageType === 1 }]"
                    @click="packageType = 1; $gtm.trackEvent({ category: 'Tarieven Toggle', value: 'Vereniging' }); calculatePrice();"
                  >
                    Vereniging
                    <span :class="['opacity-0 transition-opacity absolute left-1/2 -translate-x-1/2 text-sm -top-[42px] bg-gradient-to-r from-primary-400 to-primary-500 rounded-lg text-black px-12 py-5 before:absolute before:w-8 before:h-8 before:-bottom-3 before:left-1/2 before:-translate-x-1/2 before:rotate-45 before:bg-gradient-to-tr before:from-primary-400 before:to-primary-500', { 'opacity-100': packageType === 1 }]">-50%</span>
                  </button>
                </div>
              </div>
              <div class="flex flex-col md:flex-row md:items-center mt-30">
                <label
                  for="members"
                  class="md:w-1/3 font-medium"
                >Aantal leden</label>
                <div class="flex-1 flex gap-x-10">
                  <input
                    id="members"
                    v-model.number="yourPackage.members"
                    type="range"
                    name="members"
                    class="input-range flex-1"
                    min="5"
                    max="255"
                    step="1"
                    @change="$gtm.trackEvent({ category: 'Schuifregelaar', value: $event.target.value });"
                  />
                  <!-- eslint-disable vuejs-accessibility/form-control-has-label -->
                  <input
                    v-model="yourPackage.members"
                    type="number"
                    min="1"
                    step="1"
                    class="block w-[80px] px-12 rounded-full h-40 bg-white text-center border border-gray-200"
                    @change="$gtm.trackEvent({ category: 'Vrij invoerveld Schuifregelaar', value: $event.target.value });"
                  />
                </div>
              </div>

              <div class="flex flex-wrap items-start mt-30">
                <div class="w-full mt-10 md:mt-0">
                  <h3 class="text-2xl font-bold mb-10">
                    Jouw <span class="marker marker-md">prijs</span>
                  </h3>
                  <dl class="flex flex-wrap mt-10 text-base">
                    <dt class="w-1/3 font-medium">
                      {{ yourPackage.members > 250 ? '250+' : yourPackage.members }} leden
                    </dt>
                    <dd class="w-2/3">
                      <span
                        v-if="packageType === 1"
                        class="relative mr-5 before:absolute before:w-full before:h-2 before:-rotate-[15deg] before:inset-x-0 before:top-1/2 before:bg-primary-500"
                      >
                        {{ formatCurrency(monthlyPrice * 2, true) }}
                      </span>

                      <span>
                        {{ formatCurrency(monthlyPrice, true) }}
                      </span>
                      per maand
                    </dd>
                  </dl>
                </div>
              </div>

              <div class="flex flex-col items-start gap-15 md:flex-row mt-50">
                <button
                  type="button"
                  class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
                  @click="showModal = true; $gtm.trackEvent({ category: 'Klik', value: 'Interesse' })"
                >
                  Neem contact op
                </button>
                <router-link
                  to="/demo-aanvragen"
                  class="flex w-fit items-center font-semibold group"
                >
                  <span class="h-50 w-50 shrink-0 mr-10 bg-black bg-opacity-5 group-hover:bg-opacity-10 transition rounded-full flex items-center justify-center">
                    <svg
                      class="shrink-0"
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      xmlns="http://www.w3.org/2000/svg"
                    ><path
                      d="M13.707 8.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 1 1 1.414-1.414L6 12.586V1a1 1 0 0 1 2 0v11.586l4.293-4.293a1 1 0 0 1 1.414 0z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    /></svg>
                  </span>
                  Gratis demo aanvragen
                </router-link>
              </div>
            </div>

            <div class="w-full lg:w-1/3 p-25 xl:p-50">
              <h3 class="text-3xl font-bold">
                <span class="marker marker-md">Éénmalige</span> kosten
              </h3>

              <dl class="flex flex-wrap mt-20 text-sm">
                <dt class="w-2/3 pt-10 font-medium">
                  Set-up van de website en app in jouw huisstijl
                </dt>
                <dd class="w-1/3 text-right pt-10">
                  € {{ setupCosts }}
                </dd>
              </dl>
              <div class="mt-20 text-sm border-t pt-20">
                <div class="flex w-full justify-between items-center">
                  <div class="flex items-center font-medium">
                    Onboarding
                    <label class="switch ml-10">
                      <input
                        id="onboarding"
                        v-model="yourPackage.onboarding"
                        type="checkbox"
                        class="input"
                      >
                      <span class="slider" />
                    </label>
                  </div>
                  <span>
                    € {{ onboardingCosts }}
                  </span>
                </div>
                <p class="text-sm text-gray-500 mt-20">
                  Clubee helpt je bij de nieuwe overstap naar je nieuwe website en het onboarden van je leden.
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="w-full mx-auto rounded-3xl bg-gray-100 p-25 md:p-50 xl:p-100">
        <div class="flex flex-wrap gap-y-20 justify-between">
          <div class="w-full lg:w-3/5 lg:pr-50">
            <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold">
              Heb jij een <span class="marker">vraag?</span><br>
              Wij helpen je graag!
            </h2>
            <p class="text-gray-500 mt-15 lg:text-xl">
              Wil je meer weten over onze oplossingen of heb je een andere vraag? Wij helpen je graag! Stuur ons een mail of bel direct met ons voor meer informatie.
            </p>
            <div class="flex flex-col items-start mt-40 lg:flex-row gap-x-25 gap-y-8">
              <a
                href="tel:+31854019360"
                class="inline-flex items-center gap-x-8 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
              >
                <span class="inline-flex items-center justify-center bg-white/80 rounded-full size-30">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.17947 9.93584C6.60157 12.4573 9.32291 13.5171 10.9072 13.9476C11.6663 14.1541 12.4524 13.7341 12.7543 12.9795L13.6345 10.7765C13.8746 10.1759 13.6137 9.48504 13.0441 9.21624L11.2508 8.36853C10.7034 8.10953 10.0585 8.23133 9.63357 8.67443L8.82867 9.51234C8.82867 9.51234 7.72185 9.20784 6.30032 7.72803C4.8788 6.24822 4.58629 5.09601 4.58629 5.09601L5.39119 4.25811C5.81617 3.81571 5.93318 3.1444 5.68505 2.5746L4.87073 0.706988C4.61252 0.114784 3.94883 -0.157517 3.37188 0.093084L1.25574 1.00939C0.530854 1.32299 0.127394 2.1413 0.325762 2.9323C0.739308 4.58151 1.75737 7.41443 4.17947 9.93584Z"
                      fill="black"
                    />
                  </svg>
                </span>
                085 - 401 9360
              </a>
              <a
                href="mailto:info@clubee.nl"
                class="inline-flex items-center gap-x-8 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full text-white bg-gradient-to-r from-gray-600 via-gray-900 to-gray-600 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
              >
                <span class="inline-flex items-center justify-center bg-white rounded-full size-30">
                  <svg
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.6 0C0.2682 0 0 0.28 0 0.625V0.938721L6 4.88525L12 0.938721V0.625C12 0.28 11.7318 0 11.4 0H0.6ZM0 2.41699V9.375C0 9.72 0.2682 10 0.6 10H11.4C11.7318 10 12 9.72 12 9.375V2.41699L6.31992 6.15356C6.22212 6.21794 6.1116 6.25 6 6.25C5.8884 6.25 5.77788 6.21794 5.68008 6.15356L0 2.41699Z"
                      fill="black"
                    />
                  </svg>
                </span>
                info@clubee.nl
              </a>
            </div>

            <div class="mt-25 lg:mt-60">
              <h6 class="text-lg xl:text-xl/tight font-semibold mb-20">
                Support
              </h6>
              <p class="text-gray-600 text-lg">
                Voor ondersteuning van je Clubee website kun je contact opnemen via
                <a
                  href="mailto:support@clubee.nl"
                  class="text-black underline hover:no-underline"
                >
                  support@clubee.nl
                </a>
                of
                <a
                  href="tel:0851304667"
                  class="text-black underline hover:no-underline"
                >
                  085-130 4667
                </a>
              </p>
            </div>
          </div>
          <div class="w-full lg:w-2/5 lg:pl-75">
            <img
              src="/img/contact.jpg"
              alt="Clubee"
              class="rounded-2xl w-full mb-20 lg:mb-50"
            >
            <ul class="leading-normal text-black">
              <li class="flex items-center space-x-5 mt-20">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                ><path
                  d="M23.62 0C24.382 0 25 .618 25 1.38v22.24A1.38 1.38 0 0 1 23.62 25H1.38A1.38 1.38 0 0 1 0 23.62V1.38C0 .618.618 0 1.38 0h22.24zm-6.517 9.596c-1.92 0-3.122 1.04-3.344 1.768V9.698H9.996c.012.226.018 1.026.02 2.093v.51l.001.268v1.424c-.004 2.46-.016 5.209-.02 6.056l-.001.166v.098h3.763v-6.06c.002-.206.017-.4.082-.552.258-.638.814-1.3 1.814-1.3 1.306 0 1.9.98 1.9 2.419v5.492h3.8V14.41c0-3.287-1.871-4.814-4.252-4.814zm-9.153.102H4.6v10.614h3.35V9.698zm-1.737-5.01c-1.237 0-2.046.794-2.046 1.842 0 1.029.786 1.84 1.999 1.84h.024c1.259 0 2.043-.814 2.043-1.843-.024-1.047-.784-1.84-2.02-1.84z"
                  fill="#007EBB"
                /></svg>
                <span>Volg ons op</span>
                <a
                  href="https://www.linkedin.com/company/clubee-nl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-black underline hover:no-underline"
                >
                  LinkedIn
                </a>
              </li>
              <li class="flex items-center space-x-5 mt-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 132 132"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <defs>
                    <linearGradient id="b">
                      <stop
                        offset="0"
                        stop-color="#3771c8"
                      />
                      <stop
                        stop-color="#3771c8"
                        offset=".128"
                      />
                      <stop
                        offset="1"
                        stop-color="#60f"
                        stop-opacity="0"
                      />
                    </linearGradient>
                    <linearGradient id="a">
                      <stop
                        offset="0"
                        stop-color="#fd5"
                      />
                      <stop
                        offset=".1"
                        stop-color="#fd5"
                      />
                      <stop
                        offset=".5"
                        stop-color="#ff543e"
                      />
                      <stop
                        offset="1"
                        stop-color="#c837ab"
                      />
                    </linearGradient>
                    <radialGradient
                      id="c"
                      cx="158.429"
                      cy="578.088"
                      r="65"
                      xlink:href="#a"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)"
                      fx="158.429"
                      fy="578.088"
                    />
                    <radialGradient
                      id="d"
                      cx="147.694"
                      cy="473.455"
                      r="65"
                      xlink:href="#b"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)"
                      fx="147.694"
                      fy="473.455"
                    />
                  </defs>
                  <path
                    fill="url(#c)"
                    d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                    transform="translate(1.004 1)"
                  />
                  <path
                    fill="url(#d)"
                    d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                    transform="translate(1.004 1)"
                  />
                  <path
                    fill="#fff"
                    d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"
                  />
                </svg>
                <span>Volg ons op</span>
                <a
                  href="https://www.instagram.com/clubee_nl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-black underline hover:no-underline"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Transition
    enter-active-class="duration-500 linear"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-500 linear"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    @after-enter="focusModalInput"
  >
    <div
      v-if="showModal"
      role="dialog"
      tabindex="-1"
      class="fixed inset-0 w-screen h-screen bg-gray-500/50 items-center justify-center z-[999] p-25 flex transition-all"
    >
      <div
        class="inset-0 absolute cursor-auto"
        role="button"
        tabindex="-1"
        @click="closeModal"
        @keydown.esc="closeModal"
      />
      <div class="relative w-full h-full max-w-screen-md">
        <button
          type="button"
          aria-label="Sluiten"
          class="absolute right-0 top-0 p-10 m-5 rounded-full hover:bg-gray-100 transition"
          @click="closeModal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-20 h-20"
          >
            <path
              fill-rule="evenodd"
              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <div class="max-h-full p-25 xl:p-50 overflow-y-auto overscroll-contain rounded-3xl bg-white">
          <h3 class="text-2xl font-bold">
            Jouw prijs
          </h3>
          <p class="text-sm text-gray-500 mt-20">
            De genoemde prijzen zijn voor de website én App, inclusief webhosting en exclusief btw.
          </p>
          <dl class="flex flex-wrap mt-10 text-sm">
            <dt class="w-full border-b border-gray-200 font-semibold py-10">
              Maandelijkse kosten
            </dt>
            <dt class="w-2/3 pt-10">
              {{ yourPackage.members > 500 ? '500+' : yourPackage.members }} leden
            </dt>
            <dd class="w-1/3 text-right pt-10">
              {{ formatCurrency(currentPrice, true) }}
            </dd>
            <template v-if="yourPackage.onboarding">
              <dt class="w-2/3 pt-10 font-medium">
                Totaal
              </dt>
              <dd class="w-1/3 text-right pt-10 font-medium">
                {{ formatCurrency(monthlyPrice, true) }}
              </dd>
            </template>
            <dt class="w-full border-b border-gray-200 font-semibold pt-20 pb-10">
              Éénmalige kosten
            </dt>
            <dt class="w-2/3 pt-10">
              Setup
            </dt>
            <dd class="w-1/3 text-right pt-10">
              € {{ setupCosts }}
            </dd>
            <template v-if="yourPackage.onboarding">
              <dt class="w-2/3 pt-10">
                Onboarding
              </dt>
              <dd class="w-1/3 text-right pt-10">
                € {{ onboardingCosts }}
              </dd>
              <dt class="w-2/3 pt-10 font-medium">
                Totaal
              </dt>
              <dd class="w-1/3 text-right pt-10 font-medium">
                € {{ oneTimePrice }}
              </dd>
            </template>
          </dl>

          <h3 class="text-2xl font-bold mt-50">
            <span class="marker marker-md">Ik heb interesse</span>
          </h3>
          <template v-if="!formSuccess">
            <p class="text-sm text-gray-500 mt-20">
              Laat je gegevens achter en we nemen contact met je op.
            </p>

            <form
              class="mt-30 text-sm"
              netlify-honeypot="date-of-birth"
              data-netlify="true"
              name="prijsaanvraag"
              method="POST"
              @submit.prevent="onFormSubmit"
            >
              <input
                name="date-of-birth"
                class="hidden"
              >
              <input
                type="hidden"
                name="form-name"
                value="prijsaanvraag"
              >
              <input
                type="hidden"
                name="Aantal leden"
                :value="yourPackage.members"
              >
              <input
                type="hidden"
                name="Pakket"
                :value="packages[packageType]"
              >
              <input
                type="hidden"
                name="Onboarding"
                :value="yourPackage.onboarding"
              >
              <input
                type="hidden"
                name="Maandelijkse kosten"
                :value="formatCurrency(monthlyPrice, true)"
              >
              <input
                type="hidden"
                name="Eenmalige kosten"
                :value="formatCurrency(oneTimePrice, true)"
              >
              <div>
                <label
                  for="naam"
                  class="font-medium"
                >Jouw naam *</label>
                <input
                  id="naam"
                  ref="formNameRef"
                  type="text"
                  name="Naam"
                  required
                  class="block w-full bg-gray-100 rounded p-10 mt-5 mb-30"
                  @change="$gtm.trackEvent({
                    event: 'form_start',
                    eventModel: {
                      form_id: 'prijsopgave',
                    },
                  })"
                />
              </div>
              <div>
                <label
                  for="clubName"
                  class="font-medium"
                >Naam club *</label>
                <input
                  id="clubName"
                  type="text"
                  name="Naam Businessclub/vereniging"
                  required
                  class="block w-full bg-gray-100 rounded p-10 mt-5 mb-30"
                />
              </div>
              <div>
                <label
                  for="email"
                  class="font-medium"
                >E-mailadres *</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  required
                  class="block w-full bg-gray-100 rounded p-10 mt-5 mb-30"
                />
              </div>
              <div>
                <label
                  for="tel"
                  class="font-medium"
                >Telefoonnummer</label>
                <input
                  id="tel"
                  type="tel"
                  name="Telefoonnummer"
                  class="block w-full bg-gray-100 rounded p-10 mt-5 mb-30"
                />
              </div>
              <div>
                <label
                  for="bericht"
                  class="font-medium"
                >Jouw bericht</label>
                <textarea
                  id="bericht"
                  name="Bericht"
                  class="block w-full bg-gray-100 rounded p-10 mt-5 mb-30"
                />
              </div>
              <button
                type="submit"
                class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full text-black bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
              >
                Verstuur
              </button>
            </form>
          </template>
          <div
            v-if="formSuccess"
            class="rounded-md bg-green-500/20 text-green-700 px-15 py-10 mt-15"
          >
            Bedankt voor je bericht, we nemen contact met je op.
          </div>
          <div
            v-if="formError"
            class="rounded-md bg-red-500/20 text-red-700 px-15 py-10 mt-15"
          >
            Er is iets misgegaan. Probeer het later nog eens.
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { defineComponent } from 'vue';
import useIntl from '@/composables/useIntl';

export default defineComponent({
  setup() {
    const { formatCurrency } = useIntl();
    return { formatCurrency };
  },
  data() {
    return {
      formSuccess: false,
      formError: false,
      showModal: false,
      currentPrice: 50,
      totalMonthPrice: 50,
      setupCosts: 500,
      onboardingCosts: 750,
      packageType: 0,
      packages: {
        0: 'businessclub',
        1: 'vereniging',
      },
      yourPackage: {
        members: 25,
        onboarding: false,
      },
      monthlyPrice: 50,
      oneTimePrice: 500,
    };
  },
  methods: {
    /**
     * Handle form submit
     *
     * @param {Event} event
     */
    async onFormSubmit(event) {
      const form = event.target;
      const formData = new FormData(form);
      const body = new URLSearchParams(formData).toString();

      try {
        const response = await fetch('/', {
          method: 'POST',
          body,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        if (!response.ok) {
          this.contactFormError = true;
          return;
        }

        form.reset();

        this.$gtm.trackEvent({
          event: 'form_submit',
          eventModel: {
            form_id: 'prijsopgave',
          },
        });

        this.formSuccess = true;
      } catch (error) {
        this.formError = true;
      }
    },
    /**
     * Calculate price based on which package users choose
     */
    calculatePrice() {
      const { members, onboarding } = this.yourPackage;
      const isType1 = this.packageType === 1;
      let price = 50;
      if (members > 25) {
        const additionalMembers = members - 25;

        price += additionalMembers * 2;
      }

      if (members > 250) {
        price = 500;
      }

      price = isType1 ? price / 2 : price;

      this.setupCosts = isType1 ? 250 : 500;
      this.onboardingCosts = isType1 ? 375 : 750;
      this.currentPrice = price;
      this.monthlyPrice = price;
      this.oneTimePrice = this.setupCosts;

      if (onboarding) {
        this.oneTimePrice += this.onboardingCosts;
      }
    },
    /**
     * Focus modal's first form input
     */
    focusModalInput() {
      this.$refs.formNameRef.focus();
    },
    /**
     * Close modal
     */
    closeModal() {
      this.showModal = false;
      this.formSuccess = false;
      this.formError = false;
    },
  },
});
</script>
