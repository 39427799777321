<!-- eslint-disable max-len, vue/max-len -->
<template>
  <section
    id="home"
    class="pt-100 md:pt-150 pb-150 md:pb-200 relative"
  >
    <div class="absolute top-0 right-0 mt-100 lg:mt-150 w-full md:w-1/2 max-w-4xl">
      <div class="w-full pt-[100%] bg-gradient-to-r from-primary-400 to-primary-500 rounded-full ml-[20%]" />
      <img
        src="/img/hero.webp"
        alt="Website, app en ledenadministratie voor jouw club met Clubee"
        title="Website, app en ledenadministratie voor jouw club met Clubee"
        class="absolute top-0 right-0 mt-50 pl-25 md:-ml-50 md:pl-0"
      >
    </div>
    <div class="container relative mt-[110%] md:mt-0 xl:mt-50 2xl:mt-100">
      <div class="w-full md:w-1/2 max-w-4xl">
        <h1 class="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold">
          Website, app en ledenadministratie voor
          <span class="marker marker-lg">jouw club</span>
        </h1>
        <p class="text-gray-500 text-lg lg:text-xl mt-25 sm:max-w-xs lg:max-w-lg">
          Clubee is de alles-in-één oplossing voor jouw businessclub, netwerkclub of vereniging.
          Start nu met een website en app in jouw huisstijl, plan evenementen en profiteer van geïntegreerde ledenadministratie.
        </p>

        <div class="flex flex-col items-start">
          <router-link
            to="/demo-aanvragen"
            class="inline-flex mt-40 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
          >
            Gratis demo aanvragen
          </router-link>

          <router-link
            :to="{
              path: '/',
              hash: '#club',
            }"
            class="inline-flex items-center mt-25 font-semibold group"
          >
            <span class="h-50 w-50 shrink-0 mr-10 bg-black bg-opacity-5 group-hover:bg-opacity-10 transition rounded-full flex items-center justify-center">
              <svg
                class="shrink-0"
                width="14"
                height="16"
                viewBox="0 0 14 16"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                d="M13.707 8.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 1 1 1.414-1.414L6 12.586V1a1 1 0 0 1 2 0v11.586l4.293-4.293a1 1 0 0 1 1.414 0z"
                fill="#000"
                fill-rule="evenodd"
              /></svg>
            </span>
            Ontdek Clubee
          </router-link>
        </div>
      </div>
    </div>
    <div class="mt-20 lg:absolute inset-x-0 bottom-20 lg:bottom-0">
      <div class="container">
        <Reviews extended />
      </div>
    </div>
  </section>

  <section
    id="club"
    class="md:mt-100 2xl:mt-150"
  >
    <div class="container">
      <div class="rounded-3xl bg-gray-100 p-25 md:p-50 xl:p-100">
        <div class="flex flex-wrap justify-between">
          <div class="w-full lg:w-2/5 lg:pr-20">
            <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold">
              Wat is Clubee? De voordelen voor <span class="marker">jouw club</span>
            </h2>
            <p class="text-gray-500 mt-15">
              Ben je op zoek naar een gemakkelijk te gebruiken en efficiënt platform voor uw businessclub, netwerkclub of vereniging, dan is Clubee de perfecte oplossing.
            </p>
            <router-link
              to="/demo-aanvragen"
              class="inline-flex mt-40 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
            >
              Gratis demo aanvragen
            </router-link>
          </div>
          <div class="w-full lg:w-3/5">
            <div class="flex flex-wrap items-start">
              <div class="w-full md:w-1/2 lg:pl-25">
                <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-50">
                  <h4 class="text-lg font-medium mb-5 text-black">
                    Professionele website en app
                  </h4>
                  <p>Binnen een dag staat je website én app in jouw huisstijl. Een website maken en beheren was nog nooit zo makkelijk.</p>
                </div>

                <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-25">
                  <h4 class="text-lg font-medium mb-5 text-black">
                    Nieuws en foto's
                  </h4>
                  <p>Plaats nieuwsberichten en verslagen van evenementen. Inclusief foto's en video's.</p>
                </div>
              </div>

              <div class="w-full md:w-1/2 md:pl-25">
                <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-25 md:mt-0">
                  <h4 class="text-lg font-medium mb-5 text-black">
                    Agenda en evenementen
                  </h4>
                  <p>Plan evenementen en creëer automatisch de agenda. Leden melden zich makkelijk aan en af via de website of app.</p>
                </div>

                <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-25">
                  <h4 class="text-lg font-medium mb-5 text-black">
                    Ledenadministratie
                  </h4>
                  <p>Beheer jouw ledenadministratie in Clubee en download incasso-opdrachten.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    id="leden"
    class="pt-100 lg:pt-150"
  >
    <div class="container">
      <div class="flex flex-col md:flex-row-reverse justify-center">
        <div class="w-full md:w-1/2 xl:w-2/5 pb-50 md:pt-50 md:pl-50 lg:pl-100">
          <h2 class="text-4xl lg:text-5xl font-bold">
            De voordelen voor <span class="marker marker-lg">jouw leden</span>
          </h2>
          <p class="text-gray-500 text-lg lg:text-xl mt-15">
            Jouw leden kunnen met Clubee zelf alles online regelen.
          </p>

          <div class="bg-gray-100 text-gray-500 text-sm rounded-2xl p-25 mt-50 ml-25 lg:ml-50">
            <h4 class="text-lg font-medium mb-5 text-black">
              Meld je aan voor evenementen
            </h4>
            <p>Meld je met één druk op de knop aan of af voor evenementen, en zie wie er nog meer komt.</p>
          </div>

          <div class="bg-gray-100 text-gray-500 text-sm rounded-2xl p-25 mt-25 mr-25 lg:mr-50">
            <h4 class="text-lg font-medium mb-5 text-black">
              Altijd up to date van belangrijk nieuws
            </h4>
            <p>Met de website en app ben je altijd op de hoogte van het laatste nieuws. Een belangrijke melding? Dan krijg je direct een mail of push-notificatie.</p>
          </div>

          <div class="bg-gray-100 text-gray-500 text-sm rounded-2xl p-25 mt-25 ml-25 lg:ml-50">
            <h4 class="text-lg font-medium mb-5 text-black">
              Bekijk foto's van evenementen
            </h4>
            <p>Bekijk foto's en video's van afgelopen evenementen op de website of app.</p>
          </div>
        </div>

        <div class="w-full md:w-1/2 xl:w-2/5">
          <img
            src="/img/bgt.jpg"
            alt="Piet Klingeman, BGT Tubbergen"
            title="Piet Klingeman, BGT Tubbergen"
            class="rounded-3xl"
          >
          <div class="border-l-[5px] border-primary-500 pl-30 mr-20 mt-50">
            <h4 class="text-2xl lg:text-3xl font-medium mb-10">
              “Clubee biedt een gebruiksvriendelijk platform voor clubbeheer, waardoor taken als evenement- en ledenbeheer eenvoudig en efficiënt worden afgehandeld.”
            </h4>
            <p class="text-lg text-gray-500">
              &mdash; Raymond Keemers, BGT Tubbergen
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mt-100 2xl:mt-150">
    <div class="container">
      <div class="rounded-3xl grid lg:grid-cols-2 bg-gradient-to-br from-primary-400 to-primary-500">
        <div class="col-span-1 p-25 md:p-50 xl:p-75 xl:pr-0">
          <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold mb-30">
            Bereken nu de tarieven voor <span class="marker marker-white">jouw club</span>
          </h2>
          <p class="lg:pr-100 mb-40">
            Met Clubee laat je makkelijk en supersnel jouw website en app ontwikkelen, en dat voor een goedkoop tarief. Wil je weten wat de kosten voor jou app inclusief website en ledenadministratie zijn? Bereken het zelf met onze handige tool, of neem contact op voor meer informatie.
          </p>
          <router-link
            to="/tarieven"
            class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full text-white bg-gradient-to-r from-gray-600 via-gray-900 to-gray-600 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
          >
            Bereken direct
          </router-link>
        </div>
        <div class="col-span-1">
          <img
            src="/img/tarieven.png"
            alt="kosten verenigingen softwaren"
            title="kosten verenigingen software"
          >
        </div>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="w-full xl:w-4/5 mx-auto">
        <div class="flex flex-wrap justify-between items-end">
          <div class="w-full md:w-1/2 md:pr-25">
            <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold">
              <span class="marker">Voorbeelden</span>
              van Clubee klanten
            </h2>
            <p class="text-lg text-gray-500 mt-20">
              Benieuwd hoe jouw website en App eruit kan zien? Bekijk een voorbeeld van een businessclub en een vereniging die jou voorgingen.
            </p>
          </div>
          <div class="flex flex-col md:flex-row gap-20 mt-50">
            <div class="flex-1 bg-gray-100 rounded-3xl p-10 overflow-hidden flex flex-col justify-between">
              <img
                class="ml-auto -mr-10"
                src="/img/case-bgt.webp"
                alt="BGT Tubbergen"
                title="BGT Tubbergen"
              >
              <a
                href="https://bgt-tubbergen.nl/"
                target="_blank"
                rel="noopener noreferrer"
                class="relative block bg-white rounded-2xl p-25 lg:p-40 group hover:no-underline hover:bg-gray-200 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-20 h-20 absolute top-0 right-0 m-25 lg:m-40"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
                <h5 class="text-xl font-bold mb-10 group-hover:underline">
                  BGT Tubbergen
                </h5>
                <p class="text-gray-500">
                  BGT Tubbergen is een businessclub met bijna 300 leden.
                  Het organiseren van evenementen en het beheren van de ledenadministratie is nog nooit zo makkelijk geweest.
                  Leden blijven op de hoogte middels nieuwsberichten en evenementverslagen met foto's en video's.
                </p>
              </a>
            </div>
            <div class="flex-1 bg-gray-100 rounded-3xl p-10 overflow-hidden flex flex-col justify-between">
              <a
                href="https://www.rtctwente.nl/"
                target="_blank"
                rel="noopener noreferrer"
                class="relative block bg-white rounded-2xl p-25 lg:p-40 group hover:no-underline hover:bg-gray-200 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-20 h-20 absolute top-0 right-0 m-25 lg:m-40"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
                <h5 class="text-xl font-bold mb-10 group-hover:underline">
                  RTC Twente
                </h5>
                <p class="text-gray-500">
                  Rijwiel Tourclub Twente is een actieve fietsvereniging met veel verschillende niveau's en meer dan 300 leden.
                  Clubee blijkt een ideaal platform voor het organiseren van toertochten en het beheren van de ledenadministratie.
                </p>
              </a>
              <img
                src="/img/case-rtc.webp"
                alt="RTC Twente"
                title="RTC Twente"
                class="-ml-10 -mb-10"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    id="contact"
    class="pt-100 lg:pt-150 pb-50"
  >
    <div class="container">
      <div class="w-full xl:w-4/5 mx-auto">
        <div class="flex flex-wrap items-start">
          <div class="w-full lg:w-1/2 xl:w-3/5">
            <div class="bg-gradient-to-br from-primary-400 to-primary-500 p-25 lg:p-50 rounded-3xl">
              <h2 class="text-3xl xl:text-4xl font-bold mb-10">
                Vraag nu een <span class="marker marker-white">gratis demo</span> aan
              </h2>
              <template v-if="!contactFormSuccess">
                <p class="text-gray-700">
                  Vraag een gratis demo aan en kom erachter of Clubee past bij jouw businessclub of vereniging.
                </p>
                <form
                  class="mt-30"
                  netlify-honeypot="date-of-birth"
                  data-netlify="true"
                  name="contact"
                  method="POST"
                  @submit.prevent="onFormSubmit"
                >
                  <input
                    name="date-of-birth"
                    class="hidden"
                  >
                  <input
                    type="hidden"
                    name="form-name"
                    value="contact"
                  >
                  <div>
                    <label
                      for="naam"
                      class="font-medium"
                    >Jouw naam *</label>
                    <input
                      id="naam"
                      type="text"
                      name="Naam"
                      required
                      class="block w-full bg-white rounded p-10 mt-5 mb-30"
                      @change="$gtm.trackEvent({
                        event: 'form_start',
                        eventModel: {
                          form_id: 'demo',
                        },
                      })"
                    />
                  </div>
                  <div>
                    <label
                      for="clubName"
                      class="font-medium"
                    >Naam club *</label>
                    <input
                      id="clubName"
                      type="text"
                      name="Naam Businessclub/vereniging"
                      required
                      class="block w-full bg-white rounded p-10 mt-5 mb-30"
                    />
                  </div>
                  <div>
                    <label
                      for="email"
                      class="font-medium"
                    >E-mailadres *</label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      required
                      class="block w-full bg-white rounded p-10 mt-5 mb-30"
                    />
                  </div>
                  <div>
                    <label
                      for="tel"
                      class="font-medium"
                    >Telefoonnummer</label>
                    <input
                      id="tel"
                      type="tel"
                      name="Telefoonnummer"
                      class="block w-full bg-white rounded p-10 mt-5 mb-30"
                    />
                  </div>
                  <div>
                    <label
                      for="bericht"
                      class="font-medium"
                    >Jouw bericht *</label>
                    <textarea
                      id="bericht"
                      name="Bericht"
                      required
                      class="block w-full bg-white rounded p-10 mt-5 mb-30"
                    />
                  </div>
                  <button
                    type="submit"
                    class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full text-white bg-gradient-to-r from-gray-600 via-gray-900 to-gray-600 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
                  >
                    Verstuur
                  </button>
                </form>
              </template>
              <div
                v-if="contactFormSuccess"
                class="rounded-md bg-green-500/20 text-green-700 px-15 py-10 mt-15"
              >
                Bedankt voor je bericht, we nemen contact met je op.
              </div>
              <div
                v-if="contactFormError"
                class="rounded-md bg-red-500/20 text-red-700 px-15 py-10 mt-15"
              >
                Er is iets misgegaan. Probeer het later nog eens.
              </div>
            </div>
          </div>
          <div class="lg:w-1/2 xl:w-2/5 lg:pl-50 leading-loose pt-50">
            <h5 class="text-3xl xl:text-4xl font-bold mb-10">
              Neem contact op
            </h5>
            <p class="text-gray-500 leading-normal">
              Heb je een vraag of wil je weten wat Clubee voor jouw businessclub of vereniging kan betekenen? Neem dan contact met ons op, wij helpen je graag!
            </p>

            <div class="flex flex-col md:flex-row gap-20 mt-20 items-start flex-wrap">
              <img
                src="/img/contact.jpg"
                alt="Clubee"
                class="rounded-2xl w-150"
              />
              <ul class="leading-normal text-black">
                <li class="flex items-center space-x-5 mt-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="text-primary-500"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>Bel naar</span>
                  <a
                    href="tel:+31854019360"
                    class="text-black underline hover:no-underline"
                  >
                    085 - 401 9360
                  </a>
                </li>
                <li class="flex items-center space-x-5 mt-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="text-primary-500"
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                    <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                  </svg>
                  <span>Mail naar</span>
                  <a
                    href="mailto:info@clubee.nl"
                    class="text-black underline hover:no-underline"
                  >
                    info@clubee.nl
                  </a>
                </li>
                <li class="flex items-center space-x-5 mt-20">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 25 25"
                    xmlns="http://www.w3.org/2000/svg"
                  ><path
                    d="M23.62 0C24.382 0 25 .618 25 1.38v22.24A1.38 1.38 0 0 1 23.62 25H1.38A1.38 1.38 0 0 1 0 23.62V1.38C0 .618.618 0 1.38 0h22.24zm-6.517 9.596c-1.92 0-3.122 1.04-3.344 1.768V9.698H9.996c.012.226.018 1.026.02 2.093v.51l.001.268v1.424c-.004 2.46-.016 5.209-.02 6.056l-.001.166v.098h3.763v-6.06c.002-.206.017-.4.082-.552.258-.638.814-1.3 1.814-1.3 1.306 0 1.9.98 1.9 2.419v5.492h3.8V14.41c0-3.287-1.871-4.814-4.252-4.814zm-9.153.102H4.6v10.614h3.35V9.698zm-1.737-5.01c-1.237 0-2.046.794-2.046 1.842 0 1.029.786 1.84 1.999 1.84h.024c1.259 0 2.043-.814 2.043-1.843-.024-1.047-.784-1.84-2.02-1.84z"
                    fill="#007EBB"
                  /></svg>
                  <span>Volg ons op</span>
                  <a
                    href="https://www.linkedin.com/company/clubee-nl/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-black underline hover:no-underline"
                  >
                    LinkedIn
                  </a>
                </li>
                <li class="flex items-center space-x-5 mt-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 132 132"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <defs>
                      <linearGradient id="b">
                        <stop
                          offset="0"
                          stop-color="#3771c8"
                        />
                        <stop
                          stop-color="#3771c8"
                          offset=".128"
                        />
                        <stop
                          offset="1"
                          stop-color="#60f"
                          stop-opacity="0"
                        />
                      </linearGradient>
                      <linearGradient id="a">
                        <stop
                          offset="0"
                          stop-color="#fd5"
                        />
                        <stop
                          offset=".1"
                          stop-color="#fd5"
                        />
                        <stop
                          offset=".5"
                          stop-color="#ff543e"
                        />
                        <stop
                          offset="1"
                          stop-color="#c837ab"
                        />
                      </linearGradient>
                      <radialGradient
                        id="c"
                        cx="158.429"
                        cy="578.088"
                        r="65"
                        xlink:href="#a"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)"
                        fx="158.429"
                        fy="578.088"
                      />
                      <radialGradient
                        id="d"
                        cx="147.694"
                        cy="473.455"
                        r="65"
                        xlink:href="#b"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)"
                        fx="147.694"
                        fy="473.455"
                      />
                    </defs>
                    <path
                      fill="url(#c)"
                      d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                      transform="translate(1.004 1)"
                    />
                    <path
                      fill="url(#d)"
                      d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                      transform="translate(1.004 1)"
                    />
                    <path
                      fill="#fff"
                      d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"
                    />
                  </svg>
                  <span>Volg ons op</span>
                  <a
                    href="https://www.instagram.com/clubee_nl/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-black underline hover:no-underline"
                  >
                    Instagram
                  </a>
                </li>
              </ul>
            </div>

            <div class="bg-gray-100 text-gray-500 rounded-3xl p-25 mt-50 mb-25">
              <h4 class="text-lg font-medium mb-5 text-black">
                Support
              </h4>
              <p class="leading-normal">
                Voor ondersteuning van je Clubee website kun je contact opnemen via
                <a
                  href="mailto:support@clubee.nl"
                  class="text-black underline hover:no-underline"
                >support@clubee.nl</a> of
                <a
                  href="tel:+851304667"
                  class="text-black underline hover:no-underline"
                >085 - 130 4667</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import useIntl from '@/composables/useIntl';
import Reviews from '@/components/Reviews.vue';

export default defineComponent({
  components: {
    Reviews,
  },
  setup() {
    const { formatCurrency } = useIntl();
    return { formatCurrency };
  },
  data() {
    return {
      contactFormSuccess: false,
      contactFormError: false,
    };
  },
  methods: {
    /**
     * Handle form submit
     *
     * @param {Event} event
     */
    async onFormSubmit(event) {
      const form = event.target;
      const formData = new FormData(form);
      const body = new URLSearchParams(formData).toString();

      try {
        const response = await fetch('/', {
          method: 'POST',
          body,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        if (!response.ok) {
          this.contactFormError = true;
          return;
        }

        form.reset();

        this.$gtm.trackEvent({
          event: 'form_submit',
          eventModel: {
            form_id: 'demo',
          },
        });

        this.contactFormSuccess = true;
      } catch (error) {
        this.contactFormError = true;
      }
    },
  },
});
</script>
